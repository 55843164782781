<template>
  <div class="form-box">
    <h2>Sign in</h2>

    <v-form class="form" ref="form" @submit.prevent="login()">
      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <label>Email address</label>
            <v-text-field
              placeholder="Enter email address"
              required
              outlined
              class="text-field-outlined-overrides"
              v-model="email"
              :rules="validation.email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="d-flex justify-space-between">
              <label>Password</label>
              <router-link :to="{name:'VerificationEmail'}">Forgot your password?</router-link>
            </div>
            <v-text-field
              placeholder="Enter password"
              required
              outlined
              class="text-field-outlined-overrides"
              v-model="password"
              :rules="validation.passwordLogin"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="passwordVisible ? 'text' : 'password'"
              @click:append="passwordVisible = !passwordVisible"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
          >
            <v-btn
              type="submit"
              class="primary login d-block"
              width="100%"
              height="48"
            >Sign In</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import config from '../config';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data: () => {
    return {
      email: null,
      password: null,
      passwordVisible: false,
      validation: config.formValidations,
    };
  },
  methods: {
    login: async function() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('password', this.password);

      const options = {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await fetch(`${this.$store.state.apiHost}/login`, options);

      if (result.ok) {
        const {userID, role, access, status, accessTokenExpirationMS} = await result.json();

        this.$store.commit('loggedIn', 1);
        this.$store.commit('userID', userID);
        this.$store.commit('role', role);
        this.$store.commit('access', access);
        this.$store.commit('status', status);
        this.$store.commit('accessTokenExpirationMS', accessTokenExpirationMS);
        this.$router.push(({name: 'Overview'}));
      } else {
        this.$store.commit('snackbar', {text: 'Email or password is incorrect.', color: 'error'});
      }
    },
  },
}
</script>
