<template>
  <section class="content">
    <div class="d-flex">
      <h1>Releases</h1>

      <div v-if="shouldShowEmailButton()" class="flex-grow-1 text-right">
        <v-btn
          outlined
          :to="{ name: 'ReleaseEmail' }"
          width="187"
          height="48"
        >
          <v-icon
            small
            class="mr-3"
          >mdi-email-outline</v-icon>
          Release Email
        </v-btn>
      </div>
    </div>

    <p>Each SDK release contains the actual SDK package, a sample application, and documentation. </p>

    <div class="mb-12">
      <h2>iOS SDK</h2>
      <p>Our iOS SDK releases are <a href="https://developer.apple.com/documentation/xcode/creating-a-multi-platform-binary-framework-bundle" target="_blank">XCFramework bundles</a>.</p>
      <ReleasesList
        v-bind:releases="iosSdkReleases"
        v-bind:headers="headers"
        v-bind:loading="loading"
      ></ReleasesList>
    </div>

    <div>
      <h2>Android SDK</h2>
      <p>Our Android releases are <a href="https://developer.android.com/studio/projects/android-library" target="_blank">Android libraries</a>.</p>
      <ReleasesList
        v-bind:releases="androidSdkReleases"
        v-bind:headers="headers"
        v-bind:loading="loading"
      ></ReleasesList>
    </div>
  </section>
</template>

<script>
import constants from 'august-constants';

import config from '../config';
import util from '../util';
import ReleasesList from '../components/ReleasesList.vue';

export default {
  data: () => {
    return {
      releases: [],
      headers: config.releases.headers,
      loading: true,
    };
  },
  components: {
    ReleasesList
  },
  created: async function () {
    await this.getReleases();

    this.loading = false;
  },
  methods: {
    getReleases: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/releases`, options);

      if (result.ok) {
        this.releases = await result.json();
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get releases.', color: 'error'});
      }
    },
    shouldShowEmailButton: function () {
      return this.$store.state.role === constants.DeveloperRoles.GLOBAL_ADMIN;
    },
  },
  computed: {
    iosSdkReleases: function () {
      return this.releases.filter(item => item.platform === 'ios' && item.type === 'sdk');
    },
    androidSdkReleases: function () {
      return this.releases.filter(item => item.platform === 'android' && item.type === 'sdk');
    },
  }
};
</script>