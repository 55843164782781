<template>
  <v-app>
    <v-main>
      <v-app-bar
        fixed
        elevation="1"
        color="white"
        class="header"
      >
        <v-toolbar-title>
          <router-link :to="{ name: 'Overview' }" class="d-flex">
            <img
              src="https://s3.us-west-2.amazonaws.com/com.august.partners.images/august_yale_logos.svg"
              width="160"
              height="50"
              alt="Yale/August"
              class="mr-8">
          </router-link>
        </v-toolbar-title>

        <v-btn
          :to="{name:'Overview'}"
          text
          tile
          class="header-button"
          active-class="button-active"
        >Documentation</v-btn>

        <v-btn
          v-if="isNotEvaluating && isNotPartnerReader"
          :to="{name:'Releases'}"
          text
          tile
          class="header-button"
          active-class="button-active"
        >Releases</v-btn>

        <v-btn
          v-if="this.$store.state.role === constants.DeveloperRoles.GLOBAL_ADMIN"
          :to="{ name: 'PartnersView' }"
          text
          tile
          class="header-button"
          active-class="button-active"
        >Partners</v-btn>

        <v-btn
          v-if="this.$store.state.role === constants.DeveloperRoles.PARTNER_ADMIN"
          :to="{ name: 'UsersView' }"
          text
          tile
          class="header-button"
          active-class="button-active"
        >Users</v-btn>

        <v-btn
          v-if="this.$store.state.role === constants.DeveloperRoles.PARTNER_ADMIN"
          :to="{ name: 'CredentialsView' }"
          text
          tile
          class="header-button"
          active-class="button-active"
        >Credentials</v-btn>

        <v-btn
          v-if="this.$store.state.role === constants.DeveloperRoles.PARTNER_ADMIN"
          :to="{ name: 'BillsView' }"
          tile
          text
          class="header-button"
          active-class="button-active"
        >Bills</v-btn>

        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="black"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              x-large
            ><v-icon>mdi-account-circle-outline</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'Account'}">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout()">
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>v{{$store.state.buildVersion}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <div class="mt-16">
        <slot />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import constants from 'august-constants';

import config from '../config';
import util from '../util';

export default {
  data: () => {
    return {
      constants,
    };
  },
  computed: {
    isNotPartnerReader: function () {
      return this.$store.state.role !== constants.DeveloperRoles.PARTNER_READER;
    },
    isNotEvaluating: function () {
      return this.$store.state.status !== constants.DeveloperStatuses.EVALUATING;
    },
  },
  methods: {
    logout: async function () {
      const options = {
        method: 'POST',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/logout`, options);

      if (result.ok) {
        util.deleteLocalStorage(this.$store);
        this.$store.commit('snackbar', {text: 'Logged out', color: 'success'});
        this.$router.push(({name: 'Index'}));
      } else {
        this.$store.commit('snackbar', {text: 'Failed to logout.', color: 'error'});
      }
    },
  },
};
</script>

<style lang="scss">
$container-max-width: 1000px;
$container-x-padding: 2rem;
$container-margin: 0 auto;

.header {
  .header-button {
    height: 64px !important;
  }

  .v-toolbar__content {
    max-width: $container-max-width;
    margin: $container-margin;
    padding: 0 $container-x-padding 0 $container-x-padding;
  }
}

.divider {
  margin-top: 75px;
}

.content {
  max-width: $container-max-width;
  margin: $container-margin;
  padding: 2rem $container-x-padding 1rem $container-x-padding;
}

.footer {
  position: fixed;
  left: 25px;
  bottom: 0;
  text-align: left;
  color: grey;
  font-size: 14px;
}

.v-toolbar__content {
  padding: 0;
}

.button-active {
  background-color: #f0f0f0;
}
</style>
