<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>

      <v-snackbar
        v-model="$store.state.snackbar.visible"
        top
        light
        outlined
        :color="$store.state.snackbar.color"
        elevation="8"
        transition="slide-y-transition"
        :timeout="$store.state.snackbar.timeout"
      >
        <div class="d-flex align-center">
          <v-icon
            class="mr-2"
            :color="$store.state.snackbar.color"
          >{{$store.state.snackbar.icon}}</v-icon>

          <span class="snackbar-text">{{$store.state.snackbar.text}}</span>
        </div>

        <template v-slot:action="{ attrs }">
          <v-icon
            v-bind="attrs"
            @click="$store.state.snackbar.visible = false"
          >mdi-close-circle-outline</v-icon>
        </template>
      </v-snackbar>
    </component>
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import 'scss/variables.scss';
@import 'scss/layout.scss';

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('./fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url('./fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body,
.theme--light.v-application {
  color: $body-font-color !important;
  font-size: 16px;
  font-family: 'ProximaNova-Regular';
}
.theme--light.v-input input,
.theme--light.v-input textarea,
.snackbar-text {
  color: $body-font-color !important;
}

label {
  font-family: 'ProximaNova-Semibold';
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'ProximaNova-Regular';
  margin-bottom: 2rem;
}
h1 {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.04em;
}
h2 {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
}

/* Border on a text input that isn't selected. */
.v-text-field--outlined fieldset {
  border-color: transparent !important;
  border-width: 2px !important;
}

/* Border on a text input that is selected */
.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border-color: #262626 !important;
  border-width: 2px !important;
}

/* Border on a text input that is in an error state */
.v-text-field--outlined.v-input--has-state.error--text fieldset {
  border-color: #E11900 !important;
  border-width: 2px !important;
}

/* Text input error message */
.v-text-field__details {
  padding-left: 0 !important;
  margin-top: 0.5rem;

  .v-messages__message {
    font-size: 14px;
  }
}
.text-field-outlined-overrides .v-input__slot {
  background-color: #eeeeee !important;
}

a,
.v-application a {
  // color: #0071DA !important;
  text-decoration: none;
}

.v-btn {
  font-family: 'ProximaNova-Semibold';

  &.v-btn--outlined {
    border: thin solid #eeeeee;
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 8px 16px !important;
}

.v-btn.primary {
  background-color: #0071DA;

  .v-btn__content {
    color: #fff;
    text-transform: none;
  }
}

.v-expansion-panel::before {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1) !important;
}
.v-expansion-panel-header {
  font-family: 'ProximaNova-Semibold';
  background-color: #eee;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1),
              0.3s padding cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}
button.v-expansion-panel-header {
  color: #999;
}
.v-expansion-panel-header.v-expansion-panel-header--active {
  color: inherit;
  background-color: #fff;
  padding-bottom: 1rem;
}

.shadow-box {
  margin: 2rem 0 4rem 0;
  padding: 4rem;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.v-data-table {
  .v-data-table-header {
    th {
      background-color: #fafafa;
    }
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #262626 !important;
}

/* Router transition */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateX(-10px);
}

.flex-1 {
  flex: 1;
}
.flex-gap-1 {
  gap: 0.5rem;
}
.flex-gap-2 {
  gap: 1rem;
}
</style>