<template>
  <!-- <section class="side-nav">Side Nav</section> -->

  <section class="content">
    <h1>Account</h1>

    <p class="mb-8">These are the settings for your own account.</p>

    <section v-if="user">
      <div class="mb-6 d-flex align-center">
        <p class="field-label">Role</p>
        <p class="mb-0">{{user.role}}</p>
      </div>

      <div class="mb-6 d-flex align-center">
        <p class="field-label">Access</p>
        <p class="mb-0">
          <v-chip
            color="black"
            text-color="white"
            v-for="access in user.access"
            :key="access"
            class="mr-3 text-uppercase"
          >{{access}}</v-chip>
        </p>
      </div>

      <div class="mb-6 d-flex align-center">
        <p class="field-label">Status</p>
        <p class="mb-0">
          <v-chip
            v-if="user.status"
            color="black"
            text-color="white"
            class="text-uppercase"
          >{{user.status}}</v-chip>
          <span class="ml-2">{{ constants.DeveloperStatuses.getDescription(user.status) }}</span>
        </p>
      </div>

      <v-form class="form" ref="nameForm" @submit.prevent="changeName()">
        <div class="d-flex align-baseline">
          <p class="field-label">Name</p>
          <v-text-field
            v-model="user.name"
            placeholder="Enter name"
            required
            outlined
            dense
            height="48"
            class="text-field-outlined-overrides mr-4"
            :rules="validation.notEmpty"
          ></v-text-field>

          <v-btn
            type="submit"
            outlined
            width="187"
            height="48"
          >Change name</v-btn>
        </div>
      </v-form>

      <v-form class="form" ref="emailForm" @submit.prevent="changeEmail()">
        <div class="d-flex align-baseline">
          <p class="field-label">Email</p>
          <v-text-field
            v-model="user.email"
            placeholder="Enter email address"
            required
            outlined
            dense
            height="48"
            class="text-field-outlined-overrides mr-4"
            :rules="validation.email"
          ></v-text-field>

          <v-btn
            type="submit"
            outlined
            width="187"
            height="48"
          >Change email</v-btn>
        </div>
      </v-form>

      <v-form class="form" ref="passwordForm" @submit.prevent="resetPassword()">
        <div class="d-flex align-baseline">
          <p class="field-label">Password</p>
          <v-text-field
            v-model="user.password"
            placeholder="Enter password"
            required
            outlined
            dense
            height="48"
            class="text-field-outlined-overrides mr-4"
            :rules="validation.password"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            :type="passwordVisible ? 'text' : 'password'"
            @click:append="passwordVisible = !passwordVisible"
          >
            <template v-slot:message="{ message }">
              <span v-html="message"></span>
            </template>
          </v-text-field>

          <v-btn
            type="submit"
            outlined
            width="187"
            height="48"
          >Reset password</v-btn>
        </div>
      </v-form>
    </section>
  </section>
</template>

<script>
import constants from 'august-constants';

import config from '../config';
import util from '../util';

export default {
  name: 'Account',
  data: () => {
    return {
      constants,
      user: null,
      passwordVisible: false,
      validation: config.formValidations,
    };
  },
  created: async function () {
    const options = {
      method: 'GET',
      ...config.defaultFetchOptions,
    };
    const result = await util.fetch(`${this.$store.state.apiHost}/user`, options);

    if (result.ok) {
      this.user = await result.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get user info.', color: 'error'});
    }
  },
  methods: {
    changeName: async function () {
      const isValid = this.$refs.nameForm.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('name', this.user.name);

      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${this.user._id}`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Name changed', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to change name.', color: 'error'});
      }
    },
    changeEmail: async function () {
      const isValid = this.$refs.emailForm.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('email', this.user.email);

      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${this.user._id}`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Email changed', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to change email.', color: 'error'});
      }
    },
    resetPassword: async function () {
      const isValid = this.$refs.passwordForm.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('password', this.user.password);

      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${this.user._id}/password`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Password updated', color: 'success'});
      } else {
        let snackbarText = 'Failed to update password.';
        if (result.status === 400) {
          const body = await result.json();
          snackbarText = body.message;
        }

        this.$store.commit('snackbar', {text: snackbarText, color: 'error'});
      }
    },
  }
}
</script>

<style lang="scss">
.field-label {
  width: 6rem;
  font-weight: bold;
  margin-bottom: 0 !important;
}
</style>