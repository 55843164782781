'use strict';

const constants = require('august-constants');

module.exports = {
  // Options to pass to the fetch method.
  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  defaultFetchOptions: {
    credentials: 'include',
  },

  // Used by Vuetify to do form validation.
  formValidations: {
    email: [
      v => !!v || 'Please enter an email address.',
      v => /@/.test(v) || 'Please enter a valid email address.',
    ],
    password: [
      v => !!v || 'Please enter a password.',
      v => {
        if (constants.Password.isUserPasswordComplexEnough(v)) {
          return true;
        }

        return 'The password is not complex enough.';
      }
    ],
    passwordLogin: [
      v => !!v || 'Please enter a password.',
    ],
    verificationCode: [
      v => !!v || 'Please enter a 6 digit verification code.',
      v => /\d{6}/.test(v) || 'Please enter a 6 digit valid verification code.',
    ],
    name: [
      v => !!v || 'Please enter a name.',
    ],
    notEmpty: [
      v => !!v || 'Please enter something.',
    ],
    notEmptyList: [
      v => !!v || 'Please select something.',
    ],
    access: [
      v => v.length > 0 || 'Please choose at least one.',
    ],
    secureUrl: [
      v => /^https:\/\//.test(v) || 'Please enter a secure URL that starts with https://',
    ],
    secureUrlOptional: [
      v => !v || /^https:\/\//.test(v) || 'Please enter a secure URL that starts with https://',
    ],
  },

  accessToken: {
    // Refresh the access token a little before it expires so it doesn't expire
    // while we're making a request.
    expirationBufferMS: 3000,
  },

  bills: {
    headers: [{
      text: 'Year',
      value: 'year',
      sortable: false,
    }, {
      text: 'Month',
      value: 'month',
      sortable: false,
    }, {
      text: 'Locks Billed',
      value: 'locksBilled',
      sortable: false,
    },  {
      text: 'Billing Rate',
      value: 'billingRate',
      sortable: false,
    },{
      text: 'Billing Invoice',
      value: 'billUrl',
      sortable: false,
    }, {
      text: 'Audit Report',
      value: 'auditUrl',
      sortable: false,
    }],
  },

  users: {
    headers: [{
      text: 'Name',
      value: 'name',
    }, {
      text: 'Role',
      value: 'role',
    }, {
      text: 'Email',
      value: 'email',
    }, {
      text: '',
      value: 'actions',
      sortable: false,
    }],
  },

  releases: {
    headers: [{
      text: 'Version',
      value: 'version',
    }, {
      text: 'Release Date',
      value: 'releaseDate',
    }, {
      text: 'Release Notes',
      value: 'releaseNotes',
    }, {
      text: 'Migration Guide',
      value: 'migrationGuide',
    }],
  },
};
