import Vue from 'vue'
import Vuex from 'vuex'

import config from '../config';
import { version } from '../../package.json';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
      text: null,
      color: null,
      icon: null,
      visible: false,
      timeout: 4000,
    },
    apiHost: null,
    loggedIn: false,
    userID: null,
    accessTokenExpirationMS: null,
    role: null,
    buildVersion: null,
    access: null,
  },
  mutations: {
    /**
     * Load data from local storage into the Vue.js store.
     *
     * @returns {undefined}
     */
    initStore(state) {
      state.loggedIn = Number(localStorage.getItem('loggedIn'));
      state.userID = localStorage.getItem('userID');
      state.accessTokenExpirationMS = Number(localStorage.getItem('accessTokenExpirationMS'));
      state.role = localStorage.getItem('role');
      state.access = localStorage.getItem('access');

      state.apiHost = config.apiHost;
      state.buildVersion = version;
    },

    /**
     * Save an access token.
     *
     * @param {object} state - The Vuex state object.
     * @param {string} token - The access token to save.
     * @returns {undefined}
     */
    token(state, token) {
      localStorage.setItem(config.accessTokenHeader, token);
      state[config.accessTokenHeader] = token;
    },

    /**
     * Set a flag to indicate the user is logged in.
     *
     * @param {object} state - The Vuex state object.
     * @param {number} loggedIn - 1 for logged in, 0 for not.
     * @returns {undefined}
     */
    loggedIn(state, loggedIn) {
      localStorage.setItem('loggedIn', loggedIn);
      state.loggedIn = loggedIn;
    },

    /**
     * Set the user's ID.
     *
     * @param {object} state - The Vuex state object.
     * @param {number} userID -
     * @returns {undefined}
     */
    userID(state, userID) {
      localStorage.setItem('userID', userID);
      state.userID = userID;
    },

    /**
     * Set the access token expiration time (in milliseconds).
     *
     * @param {object} state - The Vuex state object.
     * @param {number} accessTokenExpirationMS -
     * @returns {undefined}
     */
     accessTokenExpirationMS(state, accessTokenExpirationMS) {
      localStorage.setItem('accessTokenExpirationMS', accessTokenExpirationMS);
      state.accessTokenExpirationMS = accessTokenExpirationMS;
    },

    /**
     * Set the user's role.
     *
     * @param {object} state - The Vuex state object.
     * @param {number} role -
     * @returns {undefined}
     */
     role(state, role) {
      localStorage.setItem('role', role);
      state.role = role;
    },

    /**
     * Set the user's access.
     *
     * @param {object} state - The Vuex state object.
     * @param {number} access -
     * @returns {undefined}
     */
    access(state, access) {
      localStorage.setItem('access', access);
      state.access = access;
    },

    /**
     * Set the user's status.
     *
     * @param {object} state - The Vuex state object.
     * @param {number} status -
     * @returns {undefined}
     */
    status(state, status) {
      localStorage.setItem('status', status);
      state.status = status;
    },

    /**
     * Show the Vuetify snackbar.
     *
     * @param {object} state - The Vuex state object.
     * @param {Object} options -
     * @param {Object} options.text - The text to put in the snackbar.
     * @param {Object} options.color - The color scheme of the snackbar.
     * @returns {undefined}
     */
    snackbar: function (state, options) {
      state.snackbar.text = options.text;
      state.snackbar.color = options.color;
      switch (options.color) {
        case 'success':
          state.snackbar.icon = 'mdi-check-bold';
          break;
        case 'error':
          state.snackbar.icon = 'mdi-alert-circle-outline';
          break;
      }
      state.snackbar.timeout = options.timeout;
      state.snackbar.visible = true;
    },
  },
  actions: {
  },
  modules: {
  }
})
