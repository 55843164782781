<template>
  <div class="content d-flex">
    <section class="pl-0">
      <div class="d-flex align-baseline">
        <h1 class="mr-8">Partner profile</h1>
        <v-btn
          text
          @click="editPartner()">
          <v-icon small class="mr-2">mdi-pencil</v-icon>Edit
        </v-btn>
      </div>

      <div class="partner" v-if="partner">
        <div class="mb-8">
          <h2>Basic information</h2>

          <div class="basic-field d-flex align-baseline mb-4">
            <p>Partner</p>
            <p>{{partner.name}}</p>
          </div>

          <div class="basic-field d-flex align-top mb-6">
            <p>Logo</p>
            <img
              :src="partner.logo"
            >
          </div>

          <div class="basic-field d-flex align-baseline mb-4">
            <p>Access</p>
            <p>
              <v-chip
                v-for="access in partner.access"
                :key="access"
                class="mr-3 text-uppercase"
                color="black"
                text-color="white"
              >{{access}}</v-chip>
            </p>
          </div>

          <div class="basic-field d-flex align-baseline mb-4">
            <p>Status</p>
            <p v-if="partner.status">
              <v-chip
                class="text-uppercase"
                color="black"
                text-color="white"
              >{{partner.status}}</v-chip>
              <span class="ml-2">{{ constants.DeveloperStatuses.getDescription(partner.status) }}</span>
            </p>
            <p v-else>
              None &mdash; <a href="" @click.prevent="editPartner()">edit the partner</a> to set it's status.
            </p>
          </div>

          <div class="basic-field mb-4">
            <p class="wide">Company description</p>
            <p>{{partner.description}}</p>
          </div>
        </div>

        <div class="mb-8">
          <h2>Technical contact</h2>

          <div v-if="partner.contacts" class="contact-field d-flex align-baseline mb-4">
            <p>Name</p>
            <v-text-field
              required
              outlined
              height="46"
              dense
              readonly
              class="text-field-outlined-overrides"
              append-icon="mdi-content-copy"
              @click:append="clickCopy(partner.contacts.technical.name)"
              v-model="partner.contacts.technical.name"
            ></v-text-field>
          </div>

          <div v-if="partner.contacts" class="contact-field d-flex align-baseline mb-4">
            <p>Email</p>
            <v-text-field
              required
              outlined
              height="46"
              dense
              readonly
              class="text-field-outlined-overrides"
              append-icon="mdi-content-copy"
              @click:append="clickCopy(partner.contacts.technical.email)"
              v-model="partner.contacts.technical.email"
            ></v-text-field>
          </div>

          <p v-if="!partner.contacts">None</p>
        </div>

        <div class="mb-8">
          <h2>URLs</h2>

          <div v-if="partner.urls" class="contact-field d-flex align-baseline mb-4">
            <p>Zendesk</p>
            <v-text-field
              required
              outlined
              height="46"
              dense
              readonly
              class="text-field-outlined-overrides"
              append-icon="mdi-content-copy"
              @click:append="clickCopy(partner.urls.zendesk)"
              v-model="partner.urls.zendesk"
            ></v-text-field>
          </div>

          <div v-if="partner.urls" class="contact-field d-flex align-baseline mb-4">
            <p>Confluence</p>
            <v-text-field
              required
              outlined
              height="46"
              dense
              readonly
              class="text-field-outlined-overrides"
              append-icon="mdi-content-copy"
              @click:append="clickCopy(partner.urls.confluence)"
              v-model="partner.urls.confluence"
            ></v-text-field>
          </div>

          <div v-if="partner.urls" class="contact-field d-flex align-baseline mb-4">
            <p>Website</p>
            <v-text-field
              required
              outlined
              height="46"
              dense
              readonly
              class="text-field-outlined-overrides"
              append-icon="mdi-content-copy"
              @click:append="clickCopy(partner.urls.website)"
              v-model="partner.urls.website"
            ></v-text-field>
          </div>
        </div>

        <div>
          <h2>Credentials</h2>

          <div v-if="partner.credentials" class="d-flex flex-wrap">
            <v-card
              v-for="credential in partner.credentials"
              :key="credential.fields.name.value"
              class="credential"
              :class="isActiveCredential(credential) ? 'full' : ''"
              flat
              outlined
              tile
            >
              <v-card-title
                v-if="!isActiveCredential(credential)"
                class="px-8 py-6"
              >
                {{credential.fields.name.value}}
              </v-card-title>

              <v-card-text
                class="px-8 pt-8 text-center grey--text text--darken-4"
                v-if="!isActiveCredential(credential)"
              >
                <p class="mb-4"><v-icon class="grey--text text--darken-4">mdi-open-in-new</v-icon></p>
                <p>Open this card to view a blurred version of your credentials.</p>
              </v-card-text>

              <v-card-text
                class="pa-0 grey--text text--darken-4"
                v-if="isActiveCredential(credential)"
              >
                <CredentialComponent
                  v-bind:initialCredential="credential"
                  v-bind:scopes="scopes"
                ></CredentialComponent>
              </v-card-text>

              <v-card-actions
                class="pa-8 justify-center"
                v-if="!isActiveCredential(credential)"
              >
                <v-btn
                  width="187"
                  height="48"
                  outlined
                  @click="setActiveCredential(credential)"
                >Open</v-btn>
              </v-card-actions>
            </v-card>
          </div>

          <p v-if="!partner.credentials?.length" class="mb-8">None</p>
        </div>

        <div class="mb-8">
          <div class="d-flex">
            <h2>Users</h2>

            <div class="flex-grow-1 text-right">
              <v-btn
                outlined
                @click="addUser()"
                width="187"
                height="48"
              >
                <v-icon
                  small
                  class="mr-3"
                >mdi-account-plus</v-icon>
                Add user
              </v-btn>
            </div>
          </div>

          <Users
            ref="users"
            v-bind:users="partner.users"
            v-bind:headers="usersHeaders"
            v-bind:partnerID="$route.params.partnerID"
          ></Users>
        </div>

        <div class="mb-8">
          <h2 id="bills">Billing</h2>

          <div
            v-if="partner.billing"
            class="d-flex flex-gap-2"
          >
            <v-card
              outlined
              class="flex-1"
            >
              <v-card-title
                class="d-flex justify-space-between"
              >
                <span>{{ partner.billing.enabled ? 'Enabled' : 'Disabled' }}</span>
                <v-icon
                  size="x-large"
                  :color="partner.billing.enabled ? 'success': 'error'"
                >{{partner.billing.enabled ? 'mdi-check-circle': 'mdi-alert-circle'}}</v-icon>
              </v-card-title>
              <v-card-text>
                <span v-if="partner.billing.enabled">Billing is enabled for this partner.  The partner billing system will create monthly reports for them.</span>
                <span v-else>Billing is disabled for this partner.  The partner billing system will not create monthly reports for them.</span>
              </v-card-text>
            </v-card>

            <v-card
              outlined
              class="flex-1"
            >
              <v-card-title>
                <span class="text-capitalize">{{ constants.DeveloperBillingModels.getTitle(partner.billing.model) }}</span>
              </v-card-title>
              <v-card-text>This partner is charged for the number of active locks per month.</v-card-text>
            </v-card>

            <v-card
              v-if="partner.billing.rate !== undefined"
              outlined
              class="flex-1"
            >
              <v-card-title>{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(partner.billing.rate) }}</v-card-title>
              <v-card-text>The amount this partner is charged per lock per month.</v-card-text>
            </v-card>
          </div>

          <v-card
            v-if="partner.billing?.rateTiered"
            outlined
            class="flex-1 mt-6"
          >
            <v-card-title>Tiered</v-card-title>
            <v-card-text>
              <p>The amount this partner is charged per lock per month varies depending on how many locks they have.</p>

              <table class="rate-table">
                <tbody>
                  <tr>
                    <th class="text-left">Locks</th>
                    <td
                      v-for="item in partner.billing.rateTiered"
                      :key="item.locks"
                    >
                      {{ new Intl.NumberFormat('en-US').format(item.min) }} -
                      {{ item.max ? new Intl.NumberFormat('en-US').format(item.max) : 'Infinity' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Rate</th>
                    <td
                      v-for="item in partner.billing.rateTiered"
                      :key="item.locks"
                    >{{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.rate) }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>

          <h3 class="mt-8">Past Bills</h3>
          <BillsList
            v-if="partner.bills && partner.bills.length"
            v-bind:bills="partner.bills"
            v-bind:headers="billHeaders"
          ></BillsList>

          <p v-if="!partner.bills?.length" class="mb-8">None</p>
        </div>
      </div>
    </section>

    <v-dialog
      v-if="partner"
      v-model="dialogs.edit"
      width="600"
      height="300"
    >
      <v-card>
        <v-card-title class="pa-8">
          <h1 class="mb-0">Edit partner</h1>
        </v-card-title>
        <v-card-text
          class="px-8 pb-8 grey--text text--darken-4"
        >
          <v-form class="form" ref="form" @submit.prevent="editPartnerConfirm()">
            <div class="d-flex align-baseline">
              <p class="field-name">Name</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.name"
                  :rules="validation.notEmpty"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-top">
              <p class="field-name">Description</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-textarea
                  required
                  outlined
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.description"
                  :rules="validation.notEmpty"
                ></v-textarea>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Logo</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.logo"
                  :rules="validation.notEmpty"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Access</p>
              <p class="flex-grow-1 mx-4">
                <v-switch
                  v-model="partner.access"
                  label="API"
                  value="api"
                  inset
                  class="d-inline-block mr-8"
                  :rules="validation.access"
                ></v-switch>
                <v-switch
                  v-model="partner.access"
                  label="SDK"
                  value="sdk"
                  inset
                  class="d-inline-block"
                  :rules="validation.access"
                ></v-switch>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Status</p>
              <p class="mx-4">
                <v-btn-toggle
                  v-model="partner.status"
                  color="primary"
                >
                  <v-btn
                    v-for="status in constants.DeveloperStatuses"
                    :key="status"
                    :value="status">
                    {{ status.toUpperCase() }}
                  </v-btn>
                </v-btn-toggle>
              </p>
            </div>

            <p id="statusDescription">{{ constants.DeveloperStatuses.getDescription(partner.status) }}</p>

            <h3 class="mt-4">Technical contact</h3>

            <div class="d-flex align-baseline">
              <p class="field-name">Name</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.contacts.technical.name"
                  :rules="validation.notEmpty"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Email</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.contacts.technical.email"
                  :rules="validation.notEmpty"
                ></v-text-field>
              </p>
            </div>

            <h3 class="mt-4">URLs</h3>

            <div class="d-flex align-baseline">
              <p class="field-name">Zendesk</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.urls.zendesk"
                  :rules="validation.secureUrlOptional"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Confluence</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.urls.confluence"
                  :rules="validation.secureUrlOptional"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Website</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="partner.urls.website"
                  :rules="validation.secureUrlOptional"
                ></v-text-field>
              </p>
            </div>

            <div class="mt-8">
              <v-btn
                width="187"
                height="48"
                class="mr-5"
                dark
                type="submit"
              >Edit</v-btn>
              <v-btn
                width="187"
                height="48"
                outlined
                @click="dialogs.edit = false"
              >Cancel</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.delete"
      width="500"
      height="300"
    >
      <v-card class="delete-partner">
        <v-card-title class="pa-8 justify-center">
          <v-icon class="black--text">mdi-alert-circle</v-icon>
        </v-card-title>
        <v-card-text
          class="pb-8 grey--text text--darken-4"
        >
          <p class="mb-8">Are you sure you want to remove the partner <strong>{{partner.name}}</strong>?</p>

          <ul class="mb-8">
            <li>All users will be removed.  They will no longer be able to access this site.</li>
            <li>All OAuth credentials will be removed.  They will no longer be able to access the API or SDK.</li>
          </ul>

          <v-btn
            width="187"
            height="48"
            class="mr-5"
            dark
            @click="removePartnerConfirm()"
          >Delete partner</v-btn>
          <v-btn
            width="187"
            height="48"
            outlined
            @click="dialogs.delete = false"
          >Cancel</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

import constants from 'august-constants';

import BillsList from '../components/BillsList.vue';
import CredentialComponent from '../components/CredentialComponent.vue';
import Users from '../components/Users.vue';

import config from '../config';
import util from '../util';

export default {
  components: {
    BillsList,
    CredentialComponent,
    Users,
  },
  data: () => {
    return {
      constants,
      usersHeaders: config.users.headers,
      billHeaders: config.bills.headers,
      partner: {
        access: [],
        contacts: {
          technical: {},
        },
        urls: {},
      },
      activeCredential: null,
      dialogs: {
        edit: false,
        delete: false,
      },
      validation: config.formValidations,
      scopes: [],
    };
  },
  created: async function () {
    const partnerID = this.$route.params.partnerID;
    const options = {
      method: 'GET',
      ...config.defaultFetchOptions,
    };
    const [partnerResult, scopesResult] = await Promise.all([
      util.fetch(`${this.$store.state.apiHost}/partners/${partnerID}`, options),
      util.fetch(`${this.$store.state.apiHost}/scopes`, options)
    ]);

    if (partnerResult.ok) {
      this.partner = await partnerResult.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get partner.', color: 'error'});
    }

    if (scopesResult.ok) {
      this.scopes = await scopesResult.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get scopes.', color: 'error'});
    }
  },
  methods: {
    clickCopy: util.clickCopy,
    editPartner: function () {
      this.dialogs.edit = true;
    },
    editPartnerConfirm: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.dialogs.edit = false;
      const data = {
        name: this.partner.name,
        description: this.partner.description,
        logo: this.partner.logo,
        access: this.partner.access,
        status: this.partner.status,
        contacts: this.partner.contacts,
        urls: this.partner.urls,
      }
      const options = {
        withCredentials: true,
      };

      try {
        await util.refreshAccessTokenIfNeeded();
        await axios.put(`${this.$store.state.apiHost}/partners/${this.$route.params.partnerID}`, data, options);
        this.$store.commit('snackbar', {text: 'Partner updated', color: 'success'});
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to update partner.', color: 'error'});
      }
    },
    removePartner: function () {
      this.dialogs.delete = true;
    },
    removePartnerConfirm: async function () {
      this.dialogs.delete = false;

      const options = {
        method: 'DELETE',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/partners/${this.$route.params.partnerID}`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Partner removed', color: 'success'});
        this.$router.push({name: 'PartnersView'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to remove partner.', color: 'error'});
      }
    },
    addUser: function () {
      this.$refs.users.addUser();
    },
    isActiveCredential: function (credential) {
      if (!this.activeCredential) {
        return false;
      }

      return this.activeCredential.fields.name.value === credential.fields.name.value
    },
    setActiveCredential: function (credential) {
      this.activeCredential = credential;
    },
    removeActiveCredential: function () {
      this.activeCredential = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-partner .v-card__title .v-icon {
  font-size: 80px;
}

.basic-field {
  p:first-child {
    width: 6rem;
    font-size: 1.125rem;
    font-weight: bold;
  }

  p.wide:first-child {
    width: auto;
  }
}

.contact-field {
  p:first-child {
    width: 10rem;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.credential.v-card {
  width: 300px;
  transition: width 0.25s ease-in-out;
  margin: 0 2rem 2rem 0;
  // box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1) !important;

  &.full {
    width: 100%;
    margin: 0 0 2rem 0;
  }

  &:last-child {
    margin: 0 0 2rem 0;
  }

  .v-card__title {
    background-color: #E2F1FF;
  }
}

.rate-table {
  border-spacing: 0;

  th, td {
    color: #000;
    padding: 0.5rem 0.75rem;
  }
  tr:first-child th,
  tr:first-child td {
    border-bottom: 1px solid #ccc;
  }
  tr th:first-child {
    padding-left: 0;
  }
}

#statusDescription {
  margin: 0.5rem 0 2rem 0;
  padding-left: 8rem;
}
</style>