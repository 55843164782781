<template>
  <v-list-item
    v-if="scope.companionType === 'array'"
    class="scope"
  >
    <v-list-item-icon
      three-line
      class="d-flex align-center align-self-auto my-0 mr-4"
    >
      <!-- TODO: Figure out how to do this without muting props. -->
      <!-- eslint-disable vue/no-mutating-props -->
      <v-switch
        v-model="credential.scopes[scope.scope]"
        @change="scopeSaved(scope.scope, 'boolean', $event)"
        hide-details
        class="mt-0 pt-0"
        :disabled="$store.state.role !== constants.DeveloperRoles.GLOBAL_ADMIN"
      ></v-switch>
      <!-- eslint-enable -->
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="scope.title" class="scope-title"></v-list-item-title>
      <v-list-item-subtitle class="scope-subtitle">
        <p>{{ scope.description }}</p>

        <v-dialog
          v-model="credential.dialogVisible"
          width="600"
          height="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              outlined
            >
              {{numItems}} {{ scope.companionFieldTitle }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="pa-8 pb-3">
              <h1 class="mb-0">{{numItems}} {{ scope.companionFieldTitle }}</h1>
            </v-card-title>
            <v-card-text
              class="px-8 pb-8 grey--text text--darken-4"
            >
              <p>{{ scope.companionFieldDescription }}</p>

              <v-form class="form" ref="form" @submit.prevent="">
                <!-- TODO: Figure out how to do this without muting props. -->
                <!-- eslint-disable vue/no-mutating-props -->
                <v-textarea
                  required
                  outlined
                  dense
                  class="text-field-outlined-overrides"
                  :value="credential.scopes[scope.companionField]"
                  @input="scopeChanged(scope.companionField, scope.companionType, $event)"
                  :disabled="$store.state.role !== constants.DeveloperRoles.GLOBAL_ADMIN"
                ></v-textarea>
                <!-- eslint-enable -->

                <div v-if="$store.state.role === constants.DeveloperRoles.GLOBAL_ADMIN">
                  <v-btn
                    width="187"
                    height="48"
                    class="mr-5"
                    dark
                    type="submit"
                    @click.prevent="scopeSaved(scope.companionField, scope.companionType, credential.scopes[scope.companionField])"
                  >Save</v-btn>
                  <v-btn
                    width="187"
                    height="48"
                    outlined
                    @click="credential.dialogVisible = false"
                  >Cancel</v-btn>
                </div>

                <div v-else>
                  <v-btn
                    width="187"
                    height="48"
                    outlined
                    @click="credential.dialogVisible = false"
                  >Close</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import constants from 'august-constants';

export default {
  name: 'CredentialScopeBoolean',
  props: [
    'scope',
    'credential',
  ],
  data() {
    return {
      constants,
    };
  },
  computed: {
    numItems: function () {
      const val = this.credential.scopes[this.scope.companionField];

      return val ? val.split('\n').length : 0;
    },
  },
  methods: {
    /**
     * The user clicked the switch or the save button, so tell the parent component to save the new scope
     * value to the backend.
     *
     * @param {string} scopeName
     * @param {string} scopeType
     * @param {string} scopeValue
     * @returns {undefined}
     */
    scopeSaved: function(scopeName, scopeType, scopeValue) {
      this.$emit('scopeSaved', this.credential, scopeName, scopeType, scopeValue);
    },

    /**
     * The user typed in the text input, so tell the parent component to update state, but not save to the backend.
     *
     * @param {string} scopeName
     * @param {string} scopeType
     * @param {string} scopeValue
     * @returns {undefined}
     */
    scopeChanged: function(scopeName, scopeType, scopeValue) {
      this.$emit('scopeChanged', this.credential, scopeName, scopeType, scopeValue);
    }
  }
};
</script>