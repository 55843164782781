import axios from 'axios';

import constants from 'august-constants';

import config from './config';
import router from './router';
import store from './store';

const internals = {
  /**
   * Convert a JWT string to an august-access-token object.
   *
   * @param {string} token - A JWT.
   * @returns {object} The august-access-token object.
   */
  parseAccessToken: token => {
    const [,payload,] = token.split('.');

    return JSON.parse(Buffer.from(payload, 'base64'));
  },

  /**
   * If the access token is expiring soon, call the endpoint to renew it.
   *
   * @returns {undefined}
   */
  refreshAccessTokenIfNeeded: async () => {
    const now = Date.now();
    const accessTokenExpirationMS = store.state.accessTokenExpirationMS;
    const bufferMS = config.accessToken.expirationBufferMS;

    if (accessTokenExpirationMS < now - bufferMS) {
      const formData = new FormData();
      formData.append('userID', store.state.userID);

      const options = {
        method: 'POST',
        ...config.defaultFetchOptions,
        body: formData,
      };
      const result = await fetch(`${store.state.apiHost}/token/refresh`, options);

      if (result.ok) {
        const {userID, role, access, status, accessTokenExpirationMS} = await result.json();

        store.commit('loggedIn', 1);
        store.commit('userID', userID);
        store.commit('role', role);
        store.commit('access', access);
        store.commit('status', status);
        store.commit('accessTokenExpirationMS', accessTokenExpirationMS);
      } else {
        store.commit('snackbar', {text: 'Failed to refresh access token', color: 'error'});
      }
    }
  },

  /**
   * This is a wrapper around the fetch method implemented by browsers.  Before fetching
   * a URL we check if the user's access token needs to be refreshed.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/fetch
   * @param {string} url -
   * @param {Object} options -
   * @returns {Promise.<Error|Object>} Returns the response from the fetch method.
   */
  fetch: async (url, options) => {
    await internals.refreshAccessTokenIfNeeded();
    const result = await fetch(url, options);

    if (result.status === 401) {
      internals.deleteLocalStorage(store);
      store.commit('snackbar', {text: 'Your session has expired.  Please login again.', color: 'error'});
      router.push(({name: 'Index'}));

      throw new Error('Logging the user out');
    }

    return result;
  },

  /**
   * This is a wrapper around axios.  It has some default options.
   * And before fetching a URL we check if the user's access token needs to be refreshed.
   *
   * @see https://axios-http.com/docs/req_config
   * @param {Object} options -
   * @returns {Promise.<Error|Object>} Returns the response from axios.
   */
  axios: async (options) => {
    await internals.refreshAccessTokenIfNeeded();

    const defaultOptions = {
      withCredentials: true,
    };
    options = Object.assign(defaultOptions, options);

    return axios(options);
  },

  /**
   * Get a list of user roles and their human readable text.
   *
   * @params {Object} [options] -
   * @params {Array} [options.excludes] - A list of roles to exclude.
   * @returns {Object}
   */
  getUserRoles: function (options = {}) {
    options.excludes = options.excludes || [];

    return Object.values(constants.DeveloperRoles).map(item => {
      if (options.excludes.includes(item)) {
        return;
      }

      return {
        text: constants.DeveloperRoles.getHumanReadableRole(item),
        value: item,
        description: constants.DeveloperRoles.getRoleDescription(item),
      };
    }).filter(item => Boolean(item));
  },

  /**
   * Copy text to the clip board.
   *
   * @param {string} text
   * @returns {undefined}
   */
  clickCopy: function (text) {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.$store.commit('snackbar', {text: 'Copied', color: 'success', timeout: 2000});
  },

  /**
   * Make the browser think it's logged out by "deleting" local storage items.
   *
   * @param {Object} store - A Vuex store.
   * @returns {undefined}
   */
  deleteLocalStorage: function (store) {
    store.commit('loggedIn', 0);
    store.commit('userID', null);
    store.commit('accessTokenExpirationMS', null);
    store.commit('role', null);
    store.commit('access', null);
  }
};

export default internals;