'use strict';

const _ = require('lodash');

const defaultConfig = require('./defaults');

let envConfig = {};
if (process.env.VUE_APP_ENV) {
  envConfig = require(`./${process.env.VUE_APP_ENV}`);
}

// NOTE must be separate, can't directly export in one line
const mergedConfig = _.merge({}, defaultConfig, envConfig);
module.exports = mergedConfig;
