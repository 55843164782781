<template>
  <section class="content">
    <h1>Documentation</h1>

    <section class="cards d-flex">
      <router-link :to="{name: 'Documentation', params: {url: doc.url}}"
        class="card black--text"
        v-for="doc in docs"
        :key="doc.name"
      >
        <div class="d-flex justify-space-between mb-2">
          <h2 class="mb-0">{{ doc.name }}</h2>
          <img :src="doc.logo" class="img">
        </div>
        <p class="description">{{ doc.description }}</p>
        <div class="chip">{{ doc.chip }}</div>
      </router-link>
    </section>
  </section>
</template>

<script>
import config from '../config';
import util from '../util';

export default {
  data: () => {
    return {
      docs: null,
    };
  },
  created: async function () {
    await this.getDocs();
  },
  methods: {
    getDocs: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/docs`, options);

      if (result.ok) {
        const docs = await result.json();
        this.docs = docs;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get docs.', color: 'error'});
      }
    },
  },
};
</script>

<style lang="scss">
h1 {
  font-size: 36px;
  margin-bottom: 1rem;
}
h2 {
  margin-bottom: 1rem;
}

.cards {
  flex-wrap: wrap;

  .card {
    border: 1px solid #eee;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    width: 400px;
    padding: 1rem;
    margin-right: 2rem;
    margin-bottom: 2rem;

    .chip {
      display: inline-block;
      padding: 4px 14px 4px 10px;
      border-radius: 1rem;
      background-color: #262626;
      color: #ffffff;
      text-transform: uppercase;
    }
    .img {
      width: 60px;
      margin: -4px -11px 0 0;
    }
    .description {
      min-height: 48px;
    }
  }
}
</style>