<template>
  <div class="content d-flex">
    <section class="pl-0">
      <div class="d-flex">
        <h1 class="flex-grow-1">Manage partners</h1>

        <div>
          <v-btn
            outlined
            width="187"
            height="48"
            @click="dialogs.create = true"
          >
            <v-icon
              small
              class="mr-2"
            >mdi-plus</v-icon>
            Add partner
          </v-btn>
        </div>
      </div>

      <p>These are the partners that can access this website.</p>

      <div class="d-flex align-baseline mt-12">
        Sort by
        <v-select
          required
          outlined
          height="46"
          dense
          class="select text-field-outlined-overrides ml-4 mr-8"
          hide-details
          :items="sortings"
          item-text="text"
          item-value="value"
          v-model="currentSort"
          v-on:change="adjustSort()"
        ></v-select>

        Show status
        <v-select
          required
          outlined
          height="46"
          dense
          class="select text-field-outlined-overrides ml-4"
          hide-details
          :items="statuses"
          item-text="text"
          item-value="value"
          v-model="currentStatus"
        ></v-select>
      </div>

      <div class="mt-8" v-if="partners">
        <router-link
          v-for="partner in filteredPartners"
          :key="partner.name"
          :to="{ name: 'Partner', params: { partnerID: partner._id.toString() } }"
          class="partner mb-8 pa-8"
        >
          <div class="d-flex align-center justify-space-between mb-8">
            <img
              :src="partner.logo"
              width="50"
              loading="lazy"
            >

            <v-btn icon>
              <v-icon color="black">mdi-arrow-right-thick</v-icon>
            </v-btn>
          </div>

          <div class="field d-flex align-center">
            <p>Partner Name</p>
            <p>{{partner.name}}</p>
          </div>

          <div class="field d-flex align-center">
            <p>Access</p>
            <p>
              <v-chip
                v-for="access in partner.access"
                :key="access"
                class="mr-3"
                color="black"
                text-color="white"
              >{{access}}</v-chip>
            </p>
          </div>

          <div
            v-if="partner.status"
            class="field d-flex align-center"
          >
            <p>Status</p>
            <p>
              <v-chip
                class="mr-3"
                color="black"
                text-color="white"
              >{{partner.status}}</v-chip>
            </p>
          </div>
        </router-link>
      </div>

      <p v-if="filteredPartners && filteredPartners.length === 0">
        There are partners to show.

        <a
          v-if="currentStatus !== 'any'"
          @click.prevent="currentStatus = 'any'"
        >
          Try showing partners with any status.
      </a>
      </p>

      <v-dialog
        v-model="dialogs.create"
        width="600"
        height="300"
      >
        <v-card>
          <v-card-title class="pa-8">
            <h1 class="mb-0">Add partner</h1>
          </v-card-title>
          <v-card-text
            class="px-8 pb-8 grey--text text--darken-4"
          >
            <v-form class="form" ref="form" @submit.prevent="createPartner()">
              <div class="d-flex align-baseline">
                <p class="field-name">Name</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    required
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.name"
                    :rules="validation.name"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-top">
                <p class="field-name">Description</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-textarea
                    required
                    outlined
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.description"
                    :rules="validation.notEmpty"
                  ></v-textarea>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Logo URL</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    required
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.logo"
                    :rules="validation.notEmpty"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Access</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-switch
                    v-model="partner.access"
                    label="API"
                    value="api"
                    inset
                    class="d-inline-block mr-8"
                    :rules="validation.access"
                  ></v-switch>
                  <v-switch
                    v-model="partner.access"
                    label="SDK"
                    value="sdk"
                    inset
                    class="d-inline-block"
                    :rules="validation.access"
                  ></v-switch>
                </p>
              </div>

              <h3 class="mt-4">Technical contact</h3>

              <div class="d-flex align-baseline">
                <p class="field-name">Name</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    required
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.contacts.technical.name"
                    :rules="validation.name"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Email</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    required
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.contacts.technical.email"
                    :rules="validation.email"
                  ></v-text-field>
                </p>
              </div>

              <h3 class="mt-4">URLs</h3>

              <div class="d-flex align-baseline">
                <p class="field-name">Zendesk</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.urls.zendesk"
                    :rules="validation.secureUrlOptional"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Confluence</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.urls.confluence"
                    :rules="validation.secureUrlOptional"
                  ></v-text-field>
                </p>
              </div>

              <div class="d-flex align-baseline">
                <p class="field-name">Website</p>
                <p class="flex-grow-1 mx-4 mb-0">
                  <v-text-field
                    outlined
                    height="46"
                    dense
                    class="text-field-outlined-overrides"
                    v-model="partner.urls.website"
                    :rules="validation.secureUrlOptional"
                  ></v-text-field>
                </p>
              </div>

              <div class="mt-8">
                <v-btn
                  width="187"
                  height="48"
                  class="mr-5"
                  dark
                  type="submit"
                >Add</v-btn>
                <v-btn
                  width="187"
                  height="48"
                  outlined
                  @click="dialogs.create = false"
                >Cancel</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import constants from 'august-constants';

import config from '../config';
import util from '../util';

export default {
  name: 'PartnersView',
  data: () => {
    return {
      constants,
      partner: {
        access: [],
        contacts: {
          technical: {},
        },
        urls: {},
      },
      partners: null,
      dialogs: {
        create: false,
      },
      validation: config.formValidations,
      sortings: [{
        text: 'Name',
        value: 'name',
      }, {
        text: 'Date Added',
        value: 'createdAt',
      }],
      currentSort: 'name',
      statuses: [{
        text: 'Any',
        value: 'any',
      }, {
        text: 'Evaluating',
        value: constants.DeveloperStatuses.EVALUATING,
      }, {
        text: 'Building',
        value: constants.DeveloperStatuses.BUILDING,
      }, {
        text: 'Live',
        value: constants.DeveloperStatuses.LIVE,
      }],
      currentStatus: 'any',
    };
  },
  created: async function () {
    await this.getPartners();
    this.adjustSort();
  },
  methods: {
    getPartners: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/partners`, options);

      if (result.ok) {
        const partners = await result.json();
        this.partners = partners;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get partners.', color: 'error'});
      }
    },

    createPartner: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const data = this.partner;
      const options = {
        withCredentials: true,
      };

      try {
        await util.refreshAccessTokenIfNeeded();
        const result = await axios.post(`${this.$store.state.apiHost}/partners`, data, options);
        this.$store.commit('snackbar', {text: 'Partner created', color: 'success'});
        this.partners.push(result.data);
        this.adjustSort();
        this.dialogs.create = false;
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to create partner.', color: 'error'});
      }
    },
    adjustSort: function () {
      this.partners.sort((lhs, rhs) => lhs[this.currentSort].toLowerCase() > rhs[this.currentSort].toLowerCase());
    },
  },
  computed: {
    filteredPartners: function () {
      const partners = this.partners || [];

      return partners.filter(partner => {
        if (this.currentStatus === 'any') {
          return true;
        }

        return partner.status === this.currentStatus;
      });
    },
  },
};
</script>

<style lang="scss">
.content.has-side-nav {
  padding-left: 0;

  .side-nav {
    min-width: 11rem;
    padding: 0 1rem;
    border-right: 1px solid #cccccc;
    margin-right: 4rem;

    .side-nav-links {
      list-style-type: none;
      padding-left: 0;

      li {
        padding-bottom: 0.5rem;
        border-bottom: 2px solid #eeeeee;
        margin-bottom: 0.5rem;
      }
    }
  }
}

a.partner {
  display: block;
  width: 706px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  color: #242424 !important;

  .field {
    p:first-child {
      width: 9rem;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }
}

.select {
  max-width: 10rem;
}
</style>