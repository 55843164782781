<template>
  <div class="content d-flex">
    <section class="pl-0">
      <h1>View bills</h1>

      <div v-if="bills">
        <p class="mb-8">These are bills for access to the API and/or SDK.</p>

        <BillsList
          v-bind:bills="bills"
          v-bind:headers="headers"
        ></BillsList>
      </div>
      <div v-else>
        <p>You have no bills.</p>
      </div>
    </section>
  </div>
</template>

<script>
import config from '../config';
import util from '../util';
import BillsList from '../components/BillsList.vue';

export default {
  name: 'BillsView',
  components: {
    BillsList,
  },
  data: () => {
    return {
      bills: null,
      headers: config.bills.headers,
    };
  },
  created: async function () {
    await this.getBills();
  },
  methods: {
    getBills: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/bills`, options);

      if (result.ok) {
        const bills = await result.json();
        this.bills = bills;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get bills.', color: 'error'});
      }
    },
  },
};
</script>

<style lang="scss">
.field-name {
  width: 7rem;
  font-weight: bold;
}
.v-snack__wrapper {
  border: 0;
}
</style>