<template>
  <v-data-table
    v-if="releases"
    :headers="headers"
    :items="releases"
    :loading="loading"
    loading-text="Loading..."
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:item.version="{ item }">
      <a :href="item.download">{{ item.version }}</a>
    </template>

    <template v-slot:item.migrationGuide="{ item }">
      <span v-if="item.migrationGuide"><a :href="item.migrationGuide" target="_blank">Migration Guide</a></span>
      <span v-else>None</span>
    </template>

    <template v-slot:item.releaseNotes="{ item }">
      <span v-if="item.releaseNotes"><a :href="item.releaseNotes" target="_blank">Release Notes</a></span>
      <span v-else>None</span>
    </template>

    <template v-slot:item.releaseDate="{ item }">
      <span v-if="item.releaseDate">
        {{ Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(item.releaseDate)) }}
      </span>
      <span v-else>None</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ReleasesList',
  props: ['releases', 'headers', 'loading'],
};
</script>
