<template>
  <v-list-item
    class="scope pb-1"
  >
    <v-list-item-icon
      class="d-flex align-center align-self-auto my-0 mr-4"
    >
      <!-- TODO: Figure out how to do this without muting props. -->
      <!-- eslint-disable vue/no-mutating-props -->
      <v-switch
        v-model="credential.scopes[scope.scope]"
        @change="scopeSaved"
        hide-details
        class="mt-0 pt-0"
        :disabled="$store.state.role !== constants.DeveloperRoles.GLOBAL_ADMIN"
      ></v-switch>
      <!-- eslint-enable -->
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="scope.title" class="scope-title"></v-list-item-title>
      <v-list-item-subtitle v-text="scope.description" class="scope-subtitle"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import constants from 'august-constants';

export default {
  name: 'CredentialScopeBoolean',
  props: [
    'scope',
    'credential',
  ],
  data() {
    return {
      constants,
    };
  },
  methods: {
    /**
     * The user clicked the switch, so tell the parent component to save the new scope value to the backend.
     *
     * @param {string} scopeValue
     * @returns {undefined}
     */
    scopeSaved: function(scopeValue) {
      // Sometime we are sent `null` instead of `false` when the v-switch is turned off.
      if (typeof scopeValue !== 'boolean') {
        scopeValue = Boolean(scopeValue);
      }
      this.$emit('scopeSaved', this.credential, this.scope.scope, 'boolean', scopeValue);
    }
  }
};
</script>