<template>
  <div class="form-box">
    <h2>Confirm code</h2>

    <p>Please enter the confirmation code we just emailed to <strong>{{$route.params.email}}</strong>.</p>

    <v-form class="form" ref="form" @submit.prevent="verifyVerificationCode()">
      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
          >
            <label>Confirmation code</label>
            <v-text-field
              v-model="code"
              placeholder="Enter confirmation code"
              required
              outlined
              class="text-field-outlined-overrides"
              :rules="validation.verificationCode"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-btn
              type="submit"
              class="primary mb-4"
              width="100%"
              height="48"
            >Continue</v-btn>

            <router-link :to="{name: 'Login'}">
              <v-btn
                text
                color="primary"
                width="100%"
                height="48"
              >Return to sign in</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import config from '../config';

export default {
  name: 'VerificationCode',
  data: () => {
    return {
      validation: config.formValidations,
      code: null,
    };
  },
  methods: {
    verifyVerificationCode: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const email = this.$route.params.email;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('code', this.code);

      const options = {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await fetch(`${this.$store.state.apiHost}/verification/code`, options);

      if (result.ok) {
        const {userID, role, access, status, accessTokenExpirationMS} = await result.json();

        this.$store.commit('userID', userID);
        this.$store.commit('role', role);
        this.$store.commit('access', access);
        this.$store.commit('status', status);
        this.$store.commit('accessTokenExpirationMS', accessTokenExpirationMS);

        this.$router.push(({
          name: 'ResetPassword',
          params: {
            email: this.email,
          },
        }));
      } else {
        this.$store.commit('snackbar', {text: 'Incorrect verification code.', color: 'error'});
      }
    },
  },
}
</script>
