<template>
  <div class="content d-flex">
    <section class="pl-0">
      <div>
        <div class="d-flex">
          <h1>Manage users</h1>

          <div class="flex-grow-1 text-right">
            <v-btn
              outlined
              @click="addUser()"
              width="187"
              height="48"
            >
              <v-icon
                small
                class="mr-3"
              >mdi-account-plus</v-icon>
              Add user
            </v-btn>
          </div>
        </div>

        <p class="mb-8">These are your users that have access to this website.</p>

        <Users
          ref="users"
          v-bind:users="users"
          v-bind:headers="headers"
        ></Users>
      </div>
    </section>
  </div>
</template>

<script>
import config from '../config';
import util from '../util';
import Users from '../components/Users.vue';

export default {
  name: 'UsersView',
  components: {
    Users,
  },
  data: () => {
    return {
      users: null,
      headers: config.users.headers,
    };
  },
  created: async function () {
    await this.getUsers();
  },
  methods: {
    getUsers: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users`, options);

      if (result.ok) {
        const users = await result.json();
        this.users = users;
      } else {
        this.$store.commit('snackbar', {text: 'Failed to get users.', color: 'error'});
      }
    },
    addUser: function () {
      this.$refs.users.addUser();
    },
  },
};
</script>

<style lang="scss">
.field-name {
  width: 7rem;
  font-weight: bold;
}
.v-snack__wrapper {
  border: 0;
}
</style>