<template>
  <div class="form-box">
    <h2>Create your account</h2>

    <p>To finish creating an account for <strong>{{ $route.params.email }}</strong>, please choose a password that contains the following:</p>
    <ul class="mb-8">
      <li>At least 8 characters.</li>
      <li>A mixture of both uppercase and lowercase letters.</li>
      <li>At least one number.</li>
      <li>At least one special character, e.g., ! @ # ? ] (but not &lt; or &gt;).</li>
    </ul>

    <v-form class="form" ref="form" @submit.prevent="resetPassword()">
      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
          >
            <label>Password</label>
            <v-text-field
              v-model="password"
              placeholder="Enter a password"
              required
              outlined
              class="text-field-outlined-overrides"
              :rules="validation.password"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="passwordVisible ? 'text' : 'password'"
              @click:append="passwordVisible = !passwordVisible"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-btn
              type="submit"
              class="primary mb-4"
              width="100%"
              height="48"
            >Create account</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import config from '../config';
import util from '../util';

export default {
  name: 'RegisterView',
  data: () => {
    return {
      password: null,
      passwordVisible: false,
      validation: config.formValidations,
    };
  },
  methods: {
    resetPassword: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const email = this.$route.params.email;
      const invitationID = this.$route.params.invitationID;
      const formData = new FormData();
      formData.append('password', this.password);
      formData.append('email', email);
      formData.append('invitationID', invitationID);

      const options = {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await fetch(`${this.$store.state.apiHost}/register`, options);

      if (result.ok) {
        util.deleteLocalStorage(this.$store);
        this.$store.commit('snackbar', {text: 'Account created.  Please login.', color: 'success'});
        this.$router.push(({name: 'Login'}));
      } else {
        this.$store.commit('snackbar', {text: 'Failed to create account.', color: 'error'});
      }
    },
  },
}
</script>