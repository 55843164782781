<template>
  <div class="form-box">
    <h2>Reset your password</h2>

    <p>To start the process, please enter the email address associated with your account.</p>

    <v-form class="form" ref="form" @submit.prevent="sendVerificationCode()">
      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
          >
            <label>Email address</label>
            <v-text-field
              placeholder="Enter email address"
              v-model="email"
              required
              outlined
              class="text-field-outlined-overrides"
              :rules="validation.email"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-btn
              type="submit"
              class="primary mb-4"
              width="100%"
              height="48"
            >Continue</v-btn>

            <router-link :to="{name: 'Login'}">
              <v-btn
                text
                color="primary"
                width="100%"
                height="48"
              >Return to sign in</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import config from '../config';

export default {
  name: 'VerificationEmail',
  data: () => {
    return {
      email: null,
      validation: config.formValidations,
    };
  },
  methods: {
    sendVerificationCode: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('email', this.email);

      const result = await fetch(`${this.$store.state.apiHost}/verification/email`, {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      });

      if (result.ok) {
        this.$router.push(({
          name: 'VerificationCode',
          params: {
            email: this.email,
          },
        }));
      } else {
        this.$store.commit('snackbar', {text: 'Failed to send verification code.', color: 'error'});
      }
    },
  },
}
</script>
