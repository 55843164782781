<template>
  <section class="content d-flex">
    <div>
      <h1>SDK Release Email</h1>

      <p>Notify partners when we upload a new version of the SDK.</p>

      <div class="mb-12 release-email-box">
        <v-form ref="emailForm" @submit.prevent="confirmReleaseEmail()">
          <div class="d-flex align-baseline justify-space-between">
            <div class="mr-6 flex-grow-1">
              <p>Platform</p>
              <p class="mb-0">
                <v-select
                  required
                  outlined
                  dense
                  full-width
                  class="text-field-outlined-overrides"
                  v-model="email.platform"
                  :items="platforms"
                  :rules="validation.notEmptyList"
                ></v-select>
              </p>
            </div>

            <div class="flex-grow-1">
              <p>Version</p>
              <p class="mb-0">
                <v-text-field
                  required
                  outlined
                  dense
                  full-width
                  class="text-field-outlined-overrides"
                  v-model="email.version"
                  :rules="validation.notEmpty"
                  hint="A semver, e.g. 2.1.0"
                  persistent-hint
                ></v-text-field>
              </p>
            </div>
          </div>

          <v-divider class="mb-6 mt-2"></v-divider>

          <p>The email will look something like this.</p>
          <div>
            <p>Subject</p>
            <p class="mb-0">
              <v-text-field
                readonly
                required
                outlined
                dense
                class="text-field-outlined-overrides"
                v-model="subject"
                :rules="validation.notEmpty"
              ></v-text-field>
            </p>
          </div>

          <div>
            <p>Body</p>
            <p class="mb-0">
              <v-textarea
                readonly
                required
                outlined
                dense
                class="text-field-outlined-overrides"
                v-model="body"
                :rules="validation.notEmpty"
              ></v-textarea>
            </p>
          </div>

          <v-btn
            color="primary"
            type="submit"
            width="187"
            height="48"
            class="mr-4"
          >Send Email</v-btn>
        </v-form>
      </div>

      <v-dialog
        v-model="dialog"
        width="600"
        height="300"
      >
        <v-card>
          <v-card-title class="pa-8">
            <h2 class="mb-0">Are you sure?</h2>
          </v-card-title>
          <v-card-text
            class="px-8 pb-8 grey--text text--darken-4"
          >
            <v-form @submit.prevent="sendReleaseEmail()">
              <p>This will send an email to <strong><em>all users</em></strong> for <strong><em>every partner</em></strong> that has access to the SDK.</p>

              <div class="mt-8">
                <v-btn
                  width="187"
                  height="48"
                  class="mr-5"
                  type="submit"
                  color="primary"
                >Send</v-btn>
                <v-btn
                  width="187"
                  height="48"
                  outlined
                  @click="dialog = false"
                >Cancel</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-divider vertical class="ml-8"></v-divider>

    <div class="ml-8 flex-grow-0">
      <h2 class="mb-0">Past Emails</h2>
      <p>These are the emails we've already sent to partners.</p>
      <v-divider class="mb-0 mt-2"></v-divider>

      <v-list>
        <v-list-item v-for="item in pastEmails.data" :key="item.platform" class="px-0">
          <v-list-item-content>
            <v-list-item-title>{{ item.platform }} {{ item.version }}</v-list-item-title>
            <v-list-item-subtitle>{{ Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(item.createdAt)) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </section>
</template>

<script>
import config from '../config';
import util from '../util';

export default {
  data: () => {
    return {
      email: {
        platform: '',
        version: '',
      },
      pastEmails: {
        data: [],
        headers: [
          {text: 'Platform', value: 'platform'},
          {text: 'Version', value: 'version'},
          {text: 'Date', value: 'createdAt'},
        ],
      },
      dialog: false,
      platforms: ['iOS', 'Android'],
      headers: config.releases.headers,
      validation: config.formValidations,
    };
  },
  created: function () {
    this.getReleaseEmails();
  },
  methods: {
    isFormValid: function () {
      return this.$refs.emailForm.validate();
    },
    confirmReleaseEmail: function () {
      if (!this.isFormValid()) {
        return;
      }

      this.dialog = true;
    },
    getReleaseEmails: async function () {
      const options = {
        method: 'GET',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/releases/emails`, options);

      if (result.ok) {
        this.pastEmails.data = await result.json();
      } else {
        const error = await result.json();
        const text = error.message || 'Failed to get past emails.';
        this.$store.commit('snackbar', {text, color: 'error'});
      }
    },
    sendReleaseEmail: async function () {
      if (!this.isFormValid()) {
        return;
      }

      const formData = new FormData();
      formData.append('platform', this.email.platform);
      formData.append('version', this.email.version);

      const options = {
        method: 'POST',
        ...config.defaultFetchOptions,
        body: formData,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/releases/email`, options);

      if (result.ok) {
        this.$store.commit('snackbar', {text: 'Email sent.', color: 'success'});
        this.dialog = false;
        await this.getReleaseEmails();
      } else {
        const error = await result.json();
        const text = error.message || 'Failed to send email.';
        this.$store.commit('snackbar', {text, color: 'error'});
      }
    },
  },
  computed: {
    subject: function () {
      return `New ${this.email.platform} SDK version has been uploaded`;
    },
    body: function () {
      return `Dear partner,\nWe are excited to share with you that version ${this.email.version} of ` +
             `the ${this.email.platform} SDK has been uploaded to developer.august.com. Please ` +
             `log in, then navigate to the “Releases” tab to find the new version.\n\nThanks,\nFBIN Team`;
    }
  }
};
</script>

<style lang="scss" scoped>
.release-email-box {
  max-width: 600px;
}
</style>
