<template>
  <div>
    <v-data-table
      v-if="users"
      :headers="headers"
      :items="users"
      no-data-text="No users available"
      class="mt-4 elevation-1"
    >
      <template v-slot:item.role="{item}">
        {{getRoleText(item.role)}}
      </template>

      <template v-slot:item.email="{item}">
        {{item.email}}<br>
        <v-btn
          v-if="!item.signupComplete"
          color="primary"
          class="my-4"
          width="187"
          height="48"
          @click="resendEmail(item._id)"
        >Resend invite</v-btn>
      </template>

      <template v-slot:item.actions="{item}">
        <v-menu
          bottom
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link>
              <v-list-item-title @click="editUser(item)">
                <v-icon small class="mr-2">mdi-pencil</v-icon>
                Edit user
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="removeUser(item)">
                <v-icon small class="mr-2">mdi-close-circle</v-icon>
                Remove user
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogs.add"
      width="600"
      height="300"
    >
      <v-card class="add-user">
        <v-card-title class="pa-8">
          <h1 class="mb-0">Add user</h1>
        </v-card-title>
        <v-card-text
          class="px-8 pb-8 grey--text text--darken-4"
        >
          <v-form class="form" ref="addForm" @submit.prevent="addUserConfirm()">
            <div class="d-flex align-baseline">
              <p class="field-name">Name</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="activeUser.name"
                  :rules="validation.name"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Email address</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="activeUser.email"
                  :rules="validation.email"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-baseline">
              <p class="field-name">Role</p>
              <p class="flex-grow-1 mx-4 mb-0">
                <v-select
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  v-model="activeUser.role"
                  :items="roles"
                  item-text="text"
                  item-value="value"
                  :rules="validation.notEmptyList"
                ></v-select>
              </p>
            </div>

            <p>What is a role?</p>
            <ul>
              <li v-for="role in roles" v-bind:key="role.value">{{ role.text }}: {{ role.description }}</li>
            </ul>

            <div class="mt-8">
              <v-btn
                type="submit"
                width="187"
                height="48"
                class="mr-5"
                dark
              >Add</v-btn>
              <v-btn
                width="187"
                height="48"
                outlined
                @click="dialogs.add = false"
              >Cancel</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.delete"
      width="500"
      height="300"
    >
      <v-card class="delete-user">
        <v-card-title class="pa-8 justify-center">
          <v-icon class="black--text">mdi-alert-circle</v-icon>
        </v-card-title>
        <v-card-text
          class="pb-8 text-center grey--text text--darken-4"
        >
          <p class="mb-8">Are you sure you want to remove the user <strong>{{activeUser.name}}</strong>?</p>

          <v-btn
            width="187"
            height="48"
            class="mr-5"
            dark
            @click="removeUserConfirm()"
          >Remove user</v-btn>
          <v-btn
            width="187"
            height="48"
            outlined
            @click="dialogs.delete = false"
          >Cancel</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.edit"
      width="600"
      height="300"
    >
      <v-card class="edit-user">
        <v-card-title class="pa-8">
          <h1 class="mb-0">Edit user</h1>
        </v-card-title>
        <v-card-text
          class="px-8 pb-8 grey--text text--darken-4"
        >
          <v-form class="form" ref="editForm" @submit.prevent="editUserConfirm()">
            <div class="d-flex align-center">
              <p class="field-name">Name</p>
              <p class="flex-grow-1 mx-4">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  hide-details
                  v-model="activeUser.name"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-center">
              <p class="field-name">Email address</p>
              <p class="flex-grow-1 mx-4">
                <v-text-field
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  hide-details
                  v-model="activeUser.email"
                ></v-text-field>
              </p>
            </div>

            <div class="d-flex align-center">
              <p class="field-name">Role</p>
              <p class="flex-grow-1 mx-4">
                <v-select
                  required
                  outlined
                  height="46"
                  dense
                  class="text-field-outlined-overrides"
                  hide-details
                  v-model="activeUser.role"
                  :items="roles"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </p>
            </div>

            <div class="mt-8">
              <v-btn
                width="187"
                height="48"
                class="mr-5"
                dark
                type="submit"
              >Edit</v-btn>
              <v-btn
                width="187"
                height="48"
                outlined
                @click="dialogs.edit = false"
              >Cancel</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import constants from 'august-constants';

import config from '../config';
import util from '../util';

export default {
  name: 'Users',
  props: ['users', 'headers', 'partnerID'],
  data: () => {
    return {
      dialogs: {
        add: false,
        delete: false,
        edit: false,
      },
      activeUserIndex: 0,
      activeUser: {},
      validation: config.formValidations,
    };
  },
  computed: {
    roles: function () {
      const userRole = this.$store.state.role;
      let roles;

      if (userRole === constants.DeveloperRoles.GLOBAL_ADMIN) {
        roles = util.getUserRoles();
      } else {
        roles = util.getUserRoles({
          excludes: [constants.DeveloperRoles.GLOBAL_ADMIN],
        });
      }

      return roles;
    },
  },
  methods: {
    addUser: function () {
      this.activeUser = {};
      this.dialogs.add = true;
    },
    addUserConfirm: async function () {
      const isValid = this.$refs.addForm.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('name', this.activeUser.name);
      formData.append('email', this.activeUser.email);
      formData.append('role', this.activeUser.role);
      if (this.partnerID) {
        formData.append('partnerID', this.partnerID);
      }

      const options = {
        method: 'POST',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users`, options);

      if (result.ok) {
        const newUser = await result.json();
        this.users.push(newUser);
        this.activeUser = {};
        this.dialogs.add = false;
        this.$store.commit('snackbar', {text: 'User added', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to add user', color: 'error'});
      }
    },

    editUser: function (user) {
      this.activeUser = user;
      this.dialogs.edit = true;
    },
    editUserConfirm: async function () {
      const isValid = this.$refs.editForm.validate();
      if (!isValid) {
        return;
      }

      const formData = new FormData();
      formData.append('name', this.activeUser.name);
      formData.append('email', this.activeUser.email);
      formData.append('role', this.activeUser.role);

      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${this.activeUser._id}`, options);

      if (result.ok) {
        this.dialogs.edit = false;
        this.$store.commit('snackbar', {text: 'User edited', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to edit user', color: 'error'});
      }
    },
    removeUser: function (user) {
      this.activeUser = user;
      this.activeUserIndex = this.users.indexOf(user);
      this.dialogs.delete = true;
    },
    removeUserConfirm: async function () {
      const options = {
        method: 'DELETE',
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${this.activeUser._id}`, options);

      if (result.ok) {
        this.users.splice(this.activeUserIndex, 1);
        this.dialogs.delete = false;
        this.$store.commit('snackbar', {text: 'User removed', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to remove user', color: 'error'});
      }
    },
    resendEmail: async function (userID) {
      const formData = new FormData();
      formData.append('userID', userID);

      const options = {
        method: 'PUT',
        body: formData,
        ...config.defaultFetchOptions,
      };
      const result = await util.fetch(`${this.$store.state.apiHost}/users/${userID}/invite`, options);

      if (result.ok) {
        this.dialogs.edit = false;
        this.$store.commit('snackbar', {text: 'Invite sent', color: 'success'});
      } else {
        this.$store.commit('snackbar', {text: 'Failed to send invite', color: 'error'});
      }
    },
    getRoleText : function (value) {
      return (this.roles.find(item => item.value === value) || {}).text;
    },
  },
};
</script>

<style lang="scss">
.delete-user .v-card__title .v-icon {
  font-size: 80px;
}
.v-data-footer {
  font-size: inherit !important;
}
</style>