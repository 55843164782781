import Vue from 'vue'
import VueRouter from 'vue-router'

import constants from 'august-constants';

import Account from '../views/Account.vue'
import BillsView from '../views/BillsView.vue'
import CredentialsView from '../views/CredentialsView.vue'
import Documentation from '../views/Documentation.vue'
import IndexView from '../views/IndexView.vue'
import LayoutLoggedIn from '../layouts/LoggedIn.vue'
import LayoutNotLoggedIn from '../layouts/NotLoggedIn.vue'
import Login from '../views/Login.vue'
import Overview from '../views/Overview.vue'
import Partner from '../views/Partner.vue'
import PartnersView from '../views/PartnersView.vue'
import RegisterView from '../views/RegisterView.vue'
import ReleasesView from '../views/ReleasesView.vue'
import ReleaseEmailView from '../views/ReleaseEmailView.vue'
import ResetPassword from '../views/ResetPassword.vue'
import UsersView from '../views/UsersView.vue'
import VerificationCode from '../views/VerificationCode.vue'
import VerificationEmail from '../views/VerificationEmail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: LayoutNotLoggedIn,
      overrideAuth: true,
    },
  },
  {
    path: '/',
    name: 'Index',
    component: IndexView,
    meta: {
      overrideAuth: true,
    },
  },
  {
    path: '/verification/email',
    name: 'VerificationEmail',
    component: VerificationEmail,
    meta: {
      layout: LayoutNotLoggedIn,
      overrideAuth: true,
    },
  },
  {
    path: '/verification/code',
    name: 'VerificationCode',
    component: VerificationCode,
    meta: {
      layout: LayoutNotLoggedIn,
      overrideAuth: true,
    },
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: LayoutNotLoggedIn,
      overrideAuth: true,
    },
  },
  {
    path: '/register/:email/:invitationID',
    name: 'Register',
    component: RegisterView,
    meta: {
      layout: LayoutNotLoggedIn,
      overrideAuth: true,
    },
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      layout: LayoutLoggedIn,
    },
  },
  {
    path: '/releases',
    name: 'Releases',
    component: ReleasesView,
    meta: {
      layout: LayoutLoggedIn,
    },
  },
  {
    path: '/releases/email',
    name: 'ReleaseEmail',
    component: ReleaseEmailView,
    meta: {
      layout: LayoutLoggedIn,
    },
  },
  {
    path: '/credentials',
    name: 'CredentialsView',
    component: CredentialsView,
    meta: {
      layout: LayoutLoggedIn,
      roles: [
        constants.DeveloperRoles.PARTNER_ADMIN,
      ],
    },
  },
  {
    path: '/users',
    name: 'UsersView',
    component: UsersView,
    meta: {
      layout: LayoutLoggedIn,
      roles: [
        constants.DeveloperRoles.PARTNER_ADMIN,
      ],
    },
  },
  {
    path: '/bills',
    name: 'BillsView',
    component: BillsView,
    meta: {
      layout: LayoutLoggedIn,
      roles: [
        constants.DeveloperRoles.PARTNER_ADMIN,
      ],
    },
  },
  {
    path: '/partners',
    name: 'PartnersView',
    component: PartnersView,
    meta: {
      layout: LayoutLoggedIn,
      roles: [
        constants.DeveloperRoles.GLOBAL_ADMIN,
      ],
    },
  },
  {
    path: '/partners/:partnerID',
    name: 'Partner',
    component: Partner,
    meta: {
      layout: LayoutLoggedIn,
      roles: [
        constants.DeveloperRoles.GLOBAL_ADMIN,
      ],
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      layout: LayoutLoggedIn,
    },
  },
  {
    path: '/docs/:url',
    name: 'Documentation',
    component: Documentation,
    meta: {
      layout: LayoutLoggedIn,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // TODO: Use the Vue.js store instead of local storage.
  const loggedIn = Number(localStorage.getItem('loggedIn'));
  if (!loggedIn && !to.meta.overrideAuth) {
    return next({ name: 'Index' });
  }
  if (loggedIn && (to.name === 'Index' || to.name === 'Login')) {
    return next({ name: 'Overview' });
  }

  const userRole = localStorage.getItem('role');
  if (to.meta.roles && !to.meta.roles.includes(userRole)) {
    return next({ name: 'Overview' });
  }

  next();
});

export default router
