<template>
  <div class="content d-flex">
    <section class="pl-0">
      <div>
        <div class="d-flex">
          <h1>Manage Credentials</h1>

          <div
            v-if="showAddButton"
            class="flex-grow-1 text-right"
          >
            <v-btn
              outlined
              height="48"
              @click="dialogs.create = true"
            >
              <v-icon
                small
                class="mr-2"
              >mdi-plus</v-icon>
              Add credential
            </v-btn>
          </div>
        </div>

        <p>These are your OAuth credentials.  Use them to <router-link :to="{name: 'Documentation', params: {url: 'http://august-api-userguide.s3-website-us-east-1.amazonaws.com/oauth/oauth-dance-steps-start-here/#getting-an-access-token'}}">generate access tokens</router-link> for your users.  Once you have an access token you can make API calls on the user's behalf.</p>

        <v-alert
          v-if="user.status === constants.DeveloperStatuses.BUILDING"
          type="info"
        >Since your integration status is <em>{{ user.status }}</em>, you can only create one OAuth credential and it expires after 30 days.</v-alert>

        <v-alert
          v-if="user.status === constants.DeveloperStatuses.EVALUATING"
          type="info"
        >You cannot create OAuth credentials yet since your integration status is <em>{{ user.status }}</em>.</v-alert>

        <div
          v-else-if="credentials.length > 0"
          class="d-flex flex-wrap"
        >
          <v-card
            v-for="(credential, index1) in credentials"
            v-bind:key="credential.id"
            class="credential"
            flat
            outlined
            tile
          >
            <v-card-text
              class="pa-0 grey--text text--darken-4"
            >
              <CredentialComponent
                v-bind:initialCredential="credential"
                v-bind:index1="index1"
                v-bind:numCredentials="credentials.length"
                v-bind:scopes="scopes"
                v-bind:editable="true"
                v-on:credential-deleted="credentialDeleted"
              ></CredentialComponent>
            </v-card-text>
          </v-card>
        </div>

        <div v-else>
          <v-divider class="mb-4"></v-divider>
          Your account has no credentials. <a @click.prevent="dialogs.create = true">Create one</a>.
        </div>

        <v-dialog
          v-model="dialogs.create"
          width="600"
          height="300"
        >
          <v-card>
            <v-card-title class="pa-8">
              <h1 class="mb-0">Create credential</h1>
            </v-card-title>
            <v-card-text
              class="px-8 pb-8 grey--text text--darken-4"
            >
              <v-form class="form" ref="form" @submit.prevent="createCredential()">
                <div class="d-flex align-baseline">
                  <p class="field-name">Name</p>
                  <p class="flex-grow-1 mx-4 mb-0">
                    <v-text-field
                      required
                      outlined
                      height="46"
                      dense
                      class="text-field-outlined-overrides"
                      v-model="credential.fields.name"
                      :rules="validation.name"
                    ></v-text-field>
                  </p>
                </div>
                <p>The name the user sees when signing in via our OAuth server.</p>
                <v-divider class="mb-6"></v-divider>

                <div class="d-flex align-baseline">
                  <p class="field-name">Logo URL</p>
                  <p class="flex-grow-1 mx-4 mb-0">
                    <v-text-field
                      required
                      outlined
                      height="46"
                      dense
                      class="text-field-outlined-overrides"
                      v-model="credential.fields.logo"
                      :rules="validation.secureUrl"
                    ></v-text-field>
                  </p>
                </div>
                <p>The logo the user sees when signing in via our OAuth server.<br>We store the logo, so the URL doesn't need to be permanent.</p>
                <v-divider class="mb-6"></v-divider>

                <div class="d-flex align-start">
                  <p class="field-name">Redirect URL(s)</p>
                  <p class="flex-grow-1 mx-4 mb-0">
                    <v-textarea
                      required
                      outlined
                      dense
                      class="text-field-outlined-overrides"
                      v-model="credential.fields.redirectUris"
                      :rules="validation.secureUrl"
                    ></v-textarea>
                  </p>
                </div>
                <p>The list of URLs you can redirect to when the user finishes the OAuth sign in. One per line, must be an HTTPS URL.</p>
                <p></p>

                <div class="mt-8">
                  <v-btn
                    width="187"
                    height="48"
                    class="mr-5"
                    dark
                    type="submit"
                  >Add</v-btn>
                  <v-btn
                    width="187"
                    height="48"
                    outlined
                    @click="dialogs.create = false"
                  >Cancel</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import constants from 'august-constants';

import CredentialComponent from '../components/CredentialComponent.vue';

import config from '../config';
import util from '../util';

export default {
  components: {
    CredentialComponent,
  },
  data: () => {
    return {
      constants,
      credentials: [],
      credential: {
        fields: {},
        scopes: [],
      },
      user: {},
      dialogs: {
        create: false,
      },
      validation: config.formValidations,
      scopes: [],
    };
  },
  computed: {
    /**
     * Return true if we should show the add credential button to the user.
     *
     * @returns {boolean} -
     */
    showAddButton: function () {
      if (this.user.status === constants.DeveloperStatuses.LIVE) {
        return true;
      } else if (this.user.status === constants.DeveloperStatuses.BUILDING && this.credentials.length === 0) {
        return true;
      } else if (!this.user.status) {
        return true;
      }

      return false;
    },
  },
  created: async function () {
    const options = {
      method: 'GET',
      ...config.defaultFetchOptions,
    };
    const [scopesResult, userResult, credentialsResult] = await Promise.all([
      util.fetch(`${this.$store.state.apiHost}/scopes`, options),
      util.fetch(`${this.$store.state.apiHost}/user`, options),
      util.fetch(`${this.$store.state.apiHost}/credentials`, options)
    ]);

    if (scopesResult.ok) {
      this.scopes = await scopesResult.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get scopes.', color: 'error'});
    }

    if (userResult.ok) {
      this.user = await userResult.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get user.', color: 'error'});
    }

    if (credentialsResult.ok) {
      this.credentials = await credentialsResult.json();
    } else {
      this.$store.commit('snackbar', {text: 'Failed to get credentials.', color: 'error'});
    }
  },
  methods: {
    /**
     * The user deleted a credential so we have to remove it from memory.
     */
    credentialDeleted: function(deletedCredential) {
      for (let i = 0; i < this.credentials.length; i++) {
        if (this.credentials[i].fields.clientID.value === deletedCredential.fields.clientID.value) {
          this.credentials.splice(i, 1);
          break;
        }
      }
    },

    createCredential: async function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const data = {
        name: this.credential.fields.name,
        logo: this.credential.fields.logo,
        redirectUris: this.credential.fields.redirectUris.split('\n'),
      }
      const options = {
        withCredentials: true,
      };

      try {
        await util.refreshAccessTokenIfNeeded();
        const result = await axios.post(`${this.$store.state.apiHost}/credentials`, data, options);
        const credential = result.data;

        this.$store.commit('snackbar', {text: 'Credential created', color: 'success'});
        this.credentials.push(credential);
        this.dialogs.create = false;
      } catch (error) {
        this.$store.commit('snackbar', {text: 'Failed to create credential.', color: 'error'});
      }
    },
  },
};
</script>

<style lang="scss">
h1 {
  font-size: 36px;
  margin-bottom: 1rem;
}
h2 {
  font-size: 24px;
  margin-bottom: 1rem;
}
.credential.v-card {
  width: 100%;
  margin: 0 0 2rem 0;

  .v-card__title {
    background-color: #E2F1FF;
  }
}
</style>